.site-footer {
  width: 100%;
  height: auto;
  @media only screen and (max-width: 639px) {
    text-align: center;
  }
  .logo-container {
    background-color: $color-white;
  }
  .colofon-container {
    background-color: $color-colofon;
    border-top: 2px solid $color-colofon-border;
  }
  &__copyright {
    @media only screen and (max-width: 639px) {
      padding-top: 5px;
      font-size: rem-calc(13);
    }
  }
  &__logo {
    height: 65px;
    position: relative;
    img {
      position: absolute;
      width: 300px;
      bottom: 28px;
      left: calc(50% - 150px);
    }
  }
  &__copyright {
    height: 85px;
    p {
      padding: 30px 0;
      color: $color-copyright-text;
    }
    a {
      margin-left: 15px;
      color: $color-text;
      border-bottom: 1px solid $color-text;
      &:hover {
        color: $color-light-red;
        border-bottom: 1px solid $color-light-red;
      }
    }
  }
}
