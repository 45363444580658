.topbar {
  position: fixed;
  z-index: 10;
  height: 105px;
  width: 100%;
  top: 0;
  &__top {
    background-color: $color-white;
  }
  &__white-bar {
    position: relative;
    width: 100%;
    height: 55px;
    .home-button {
      display: inline-block;
      float: left;
      padding: 17px 0;
      margin-left: 25px;
      height: 100%;
      @media only screen and (max-width: 799px) {
        padding-top: 12px;
        margin-left: 4px;
      }
      span {
        font-size: rem-calc(14);
        color: $color-black;
        font-weight: 500;
        padding-left: 4.5px;
        @media only screen and (max-width: 799px) {
          display: none;
        }
        &::before {
            position: absolute;
            top: 14px;
            left: 22px;
            font-family: 'fontello';
            content: '\e801';
            display: block;
            color: $color-black;
            font-size: 20px;
        }
      }
      img {
        vertical-align: text-top;
      }
    }
    .logo {
      position: absolute;
      left: calc(50% - 125px);
      @media only screen and (max-width: 799px) {
        left: calc(50% - 101px);
      }
      img {
        width: 250px;
        z-index: 1;
        position: fixed;
      }
    }
  }
  .homepage &__menu {
    background-color: transparent;
  }
  &__menu {
    position: relative;
    text-align: center;
    height: 60px;
    background: $color-light-red;
    @media only screen and (max-width: 799px) {
      height: 150px;
    }
    .mobile-menu {
      @media only screen and (max-width: 799px) {
        padding: 0;
      }
    }
    .gae-menu {
      display: inline-block;
      padding-right: 10px;
      li {
        display: inline-block;
        width: 175px;
          @media only screen and (max-width: 799px) {
          display: block;
          width: 100vw;
        }
        a {
          position: relative;
          color: $color-white;
          font-size: rem-calc(21);
          padding: 23px 40px 17px;
          font-weight: 400;
          letter-spacing: 1px;
          @media only screen and (max-width: 799px) {
            height: 50px;
            padding: 21px 0 0 23px;
            font-size: rem-calc(19);
          }
          &:hover {
            &::before {
              @media only screen and (min-width: 1025px){
                position: absolute;
                bottom: -2px;
                left: 0;
                content: '';
                width: 100%;
                height: 3px;
                background-color: $color-menu-brown;
                display: block;
              }
              @media only screen and (max-width: 799px) {
                z-index: 1;
              }
            }
          }
        }
      }
      .active {
        a {
          position: relative;
          background: $color-dark-red;
          &::before {
            position: absolute;
            bottom: -2px;
            left: 0;
            content: '';
            width: 100%;
            height: 3px;
            background-color: $color-menu-brown;
            display: block;
            @media only screen and (max-width: 799px) {
              z-index: 1;
            }
          }
        }
      }
    }
  }
}

.homepage .topbar__menu .gae-menu .active a {
    background: none;
}

.homepage .topbar__menu .gae-menu .active a::before {
    display: none;
}

.hasBackground {
  background-color: $color-light-red !important;
  -webkit-transition: 1.7s ease;
}

.homebanner {
  position: relative;
  z-index: 5;
  height: 715px;
  width: 100%;
  background-image: url(/images/header_1.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  &__gradient {
    height: 130px;
    margin-top: 55px;
    background: -moz-linear-gradient(top, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
    @media only screen and (max-width: 799px) {
      height: 190px;
    }
  }
  &__searchbar {
    position: absolute;
    left: calc(50% - 450px);
    top: 55%;
    width: 900px;
    height: auto;
    @media only screen and (max-width: 799px) {
      position: absolute;
      left: 9%;
      top: 44%;
      width: 83%;
      height: auto;
    }
    .search-box {
      width: 70%;
      margin: 0 auto;
    }
    form {
      margin: 0;
      padding: 0;
      border: none;
      ::placeholder {
        color: $color-light-grey;
      }
      .search-input {
        margin: 0;
        padding: 0;
        height: 50px;
        width: 75%;
        border: none;
        color: $color-black;
        padding-left: 3%;
      }
      button {
        position: relative;
        height: 50px;
        width: 25%;
        padding: 0;
        margin: 0;
        background-color: $color-light-red;
        border: none;
        display: inline-block;
        vertical-align: top;
        &::before {
          position: absolute;
          top: 5px;
          left: 39%;
          font-family: 'fontello';
          transform: rotate(90deg);
          content: '\e810';
          display: block;
          color: $color-white;
          font-size: rem-calc(29);
          @media only screen and (max-width: 450px) {
            left: 32%;
          }
        }
      }
    }
    h1 {
      color: $color-white;
      font-size: rem-calc(60);
      font-style: italic;
      font-family: 'Georgia';
      padding: 0 0 15px 0;
      text-align: center;
      @media only screen and (max-width: 945px) {
        font-size: rem-calc(50);
      }
      @media only screen and (max-width: 600px) {
        font-size: rem-calc(38);
      }
    }
    span {
      color: $color-white;
    }
  }
}


.small-banner {
  position: relative;
  height: 175px;
  margin-top: 115px;
  background-image: url(/images/small-banner-01.png);
  background-repeat:no-repeat;
  -webkit-background-size:cover;
  -moz-background-size:cover;
  -o-background-size:cover;
  background-size:cover;
  background-position:center;
  @media only screen and (max-width: 799px) {
    margin-top: 195px;
  }
  .small-titel {
    position: absolute;
    color: white;
    font-size: rem-calc(32);
    font-style: italic;
    top: 50%;
    left: calc(50% - 240px);
    display: block;
    font-family: georgia;
    @media only screen and (max-width: 500px) {
      text-align: center;
      width: 100%;
      padding: 0 10px;
      font-size: rem-calc(21.5);
      left: 0;
      top: 70%;
    }
  }
}
