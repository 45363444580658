.com_mediabank {
  .small-banner {
    &:after {
      position: absolute;
      top: 175px;
      left: 0;
      width: 100%;
      height: 110px;
      background: $color-white-grey;
      z-index: 1;
      display: block;
      content: '';
    }
  }
}

.mediabank-search .angucomplete-dropdown {
    display: none !important;
}

.mediabank-container.mediabank-mode-detail {
  margin-top: 105px;
  @media only screen and (max-width: 799px) {
    margin-top: 210px;
  }
}

.mediabank-detail-paginator {
  top: 35px;
}

.mediabank-search .mediabank-search-fieldset {
  padding-right: 0;
}

.mediabank-button-back {
  background: $color-mediabank-red;
  &:hover {
    background: $color-mediabank-grey;
  }
}

.mediabank-button-back {
  top: 35px;
}

.mediabank-search {
  background: $color-white-grey;
}

.mediabank-detail-view .mediabank-detail-side {
  top: 109px;
  z-index: 0;
}

.mediabank-detail-view .mediabank-topviewer {
  top: 109px;
  z-index: 1;
}

.com_mediabank .gea-content {
  margin-top: 0;
}

.com_mediabank .canvas {
  background-color: $color-white;
}

.mediabank-header {
  margin-top: 90px;
  background: $color-mediabank-red;
  color: white;
  .mediabank-intro {
    padding: 21px 0;
  }
}

.pic-load-previous {
  background: $color-mediabank-red !important;
}

.mediabank-filter {
  padding: 8px 5px 8px 15px;
}

.mediabank-filter-list .mediabank-filter:hover {
    color: $color-light-red;
}

.mediabank-filter-icon {
  top: -1px !important;
}

.mediabank-detail-panel-button {
  color: $color-white;
}

.pic-mediabank {
  background-color: green;
  .mediabank-gallery-tooltip {

      .tooltip-content {
          border: 2px solid $color-black;

          &::after {
              border-top-color: $color-black;
          }
      }
  }

  .filter-active {
    background-color: $color-mediabank-red;
  }

  slider div.bar .selection, [slider] div.bar .selection {
    background-color: $color-mediabank-red;
  }

  .mediabank-filter-dropdown {
    input[type=number] {
      color: $color-black;
    }

    a {
      color: $color-white !important;
    }
  }

  .mediabank-filter-dropdown .mediabank-page-prev, .mediabank-filter-dropdown .mediabank-page-next {
    background-color: $color-mediabank-red;
  }

  .mediabank-result-header {
    background: none;
    float: left;
    margin-bottom: 0;
    padding: 0;
  }

  .mediabank-search {
    min-height: 110px;
    padding: 32px 0;

    &:after {
      position: absolute;
      background-color: $color-white-grey;
      left: 50%;
      top: 0;
      // width: 100vw;
      z-index: 1;
      height: 100%;
      display: block;
      content: '';
      transform: translateX(-50%);
    }

    form {
      position: relative;
      z-index: 2;
      ::placeholder {
        color: $color-light-grey;
      }
    }

    input[type=text] {
      position: absolute;
      border-radius: 0;
      border: none;
      height: 45px;
      width: 100%;
      left: 0;
      // @media only screen and (max-width: 1023px) {
      //   width: 23em;
      // }
      //
      // @include breakpoint(large) {
      //   height: 50px;
      //   padding: 0px 10px;
      // }
    }

    button {
      border-radius: 0;
    }

    .mediabank-search-submit {
      height: 45px;
      position: absolute;
      border-radius: 0px;
      font-size: 1.2em;
      top: 0 !important;
      background-color: $color-light-red;
      i {
        display: none;
      }

      &:before {
        font-family: "fontello";
        font-style: normal;
        font-weight: normal;
        speak: none;
        position: relative;
        content: '\e810';
        top: 0;
        display: block;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
      }

      span {
        display: none;
      }
    }
  }

  .mediabank-filter-list {
    padding-left: 0px;
  }

  .mediabank-result-options {
    margin: 20px 0;
  }

  .mediabank-gallery-mode .active a {
    color: $color-mediabank-red;
  }

  .mediabank-gallery-mode a {
    color: gray;
  }

  .mediabank-gallery-item.focused::after {
    border-bottom-color: black;
  }

  .mediabank-gallery-focused-thumb {
    background-color: black;
  }

  .mediabank-gallery-thumb {
    background-color: gray;
  }

  .mediabank-thumb-pagination a {
    background-color: $color-mediabank-red;
    color: white;
  }

  .mediabank-gallery .mediabank-gallery-thumb .spinner-wrapper {
    background-color: gray;
  }

  .spinner-wrapper .double-bounce1, .spinner-wrapper .double-bounce2 {
    background-color: $color-mediabank-red;
  }

  .pagination-button-prev, .pagination-button-next {
    background-color: $color-mediabank-red;
    &:hover {
      background-color: $color-mediabank-grey;
    }
  }

  .mediabank-detail-menu {
    background-color: $color-mediabank-grey;
  }

  .mediabank-detail-menu li {
    &:hover a, &.active a {
      color: $color-mediabank-red;
    }
  }

  .openseadragon-container {
    background: $color-mediabank-grey !important;
  }

  .mediabank-detail-paginator {
    z-index: 3;
  }

  .mediabank-detail {
    .mediabank-search-fieldset {
      width: 100%;
      margin: 0 auto;
      text-align: center;

      .angucomplete-holder {
        display: inline-block !important;
        width: 30%;
        @media only screen and (max-width: 799px) {
          width: 60%;
          margin-left: 60px;
        }
      }

      input, button {
        position: relative !important;
      }
      input {
      }
      button {
        top: 6px;
      }
    }
  }

  .mediabank-detail-panels {
    h2, h3 {
      margin-bottom: 10px;
    }

    input[type=text], textarea {
      border-color: gray;
    }

    .mediabank-comment-overview h3 {
      background: black;
      color: white;
    }

    .mediabank-comment-button {
      background: $color-mediabank-red;
    }
    .mediabank-comment-form {
      &:before {
        content: "Uw reactie wordt pas zichtbaar na controle en redactie.";
        margin-bottom: 5px;
      }
    }
    .mediabank-detail-permalink .mediabank-permalink-copy {
      background: $color-mediabank-red;
    }
  }
  .mediabank-detail-panel-close {
    background-color: black;
  }

  .mediabank-gallery-focused {
    background-color: black;
    color: white;

    .mediabank-focused-close {
      background-color: $color-mediabank-red;
    }

    .mediabank-detail-button {
      background-color: $color-mediabank-red;
    }
  }

.mediabank-results-total {
  span {
    font-size: rem-calc(16);
  }
  .total {
    font-size: rem-calc(22) !important;
  }
}

  .pic-icon-arrow-down {
    font-weight: bold;
  }
}
