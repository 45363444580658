// This is the default html and body font-size for the base rem value.
$rem-base: 16px !default;

// Functions needed for rem calculations
@function strip-unit($num) {
    @return $num / ($num * 0 + 1);
}

@function convert-to-rem($value, $base-value: $rem-base)  {
    $value: strip-unit($value) / strip-unit($base-value) * 1rem;
    @if ($value == 0rem) { $value: 0; } // Turn 0rem into 0
    @return $value;
}

@function rem-calc($values, $base-value: $rem-base) {
    $max: length($values);

    @if $max == 1 { @return convert-to-rem(nth($values, 1), $base-value); }

    $remValues: ();
    @for $i from 1 through $max {
        $remValues: append($remValues, convert-to-rem(nth($values, $i), $base-value));
    }

    @return $remValues;
}

