.advertentie {
  text-align: center;
  margin-top: 50px;
  @media only screen and (max-width: 799px) {
    margin-top: 30px;
  }
  span {
    color: $color-light-grey;
    font-size: rem-calc(16);
    display: block;
    margin-bottom: 4px;
  }
  img {
    max-width: 100%;
  }
}
