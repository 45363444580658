.canvas {
  min-height: calc(100vh - 267px);
  width: 100%;
  position: relative;
  background-color: $color-grey;
  padding-bottom: 50px;
  @media only screen and (max-width: 799px) {
    padding-bottom: 25px;
  }
}

.content-container {
  background-color: $color-white;
  margin-top: -100px;
  position: relative;
  z-index: 6;
  padding: 0 10px;
  p {
    padding: 12px 0;
    font-size: rem-calc(17);
  }
  img {
    max-width: 100%;
    width: 100%;
  }
  h2 {
    font-size: rem-calc(24);
    padding-top: 20px;
  }
  hr {
    margin: 1px 0 10px 0;
  }
}

.gea-content {
  margin-top: 50px;
  @media only screen and (max-width: 799px) {
    margin-top: 25px;
  }
  .item-page {
    background-color: $color-white;
    padding: 0 25px 15px;
  }
  p {
    padding: 12px 0;
    font-size: rem-calc(17);
  }
  img {
    max-width: 100%;
  }
  h2 {
    font-size: rem-calc(24);
    padding-top: 20px;
  }
  hr {
    margin: 1px 0 10px 0;
  }
  h3 {
    color: $color-text;
    font-size: rem-calc(19);
    padding: 8px 0;
  }
  ol {
    margin-left: 35px;
    li {
      padding: 10px 0;
      h4 {
        color: $color-light-red;
        font-size: rem-calc(16);
        font-weight: bold;
      }
      p {
        font-size: rem-calc(16);
        padding: 0;
      }
    }
  }
}
