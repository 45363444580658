.error-page {
  .topbar {
    width: 100vw;
    height: 90px;
    .menu {
      a {
        float: left;
      }
      img {
        max-height: 90px;
        width: 100%;
        padding: 9px 0;
        @media only screen and (max-width: 615px) {
          width: 80%;
        }
      }
    }
  }
  .error-content {
    height: calc(100vh - 90px);
    background: $color-grey;
    .container {
      position: relative;
      padding-top: 50px;
      .error-code {
        top: 50px;
        left: calc(50% - 76px);
        width: 125px;
        height: 125px;
        line-height: 125px;
        font-size: 3.5rem;
        box-shadow: 0 2px 2px rgba(0,0,0,0.5);
        display: block;
        position: absolute;
        z-index: 9;
        border-radius: 100%;
        background: $color-light-red;
        color: $color-white;
        text-align: center;
      }
      .content {
        a {
          color: $color-text;
          &:hover {
            color: $color-light-red;
          }
        }
        li {
          list-style-type: none;
        }
        p {
          padding: 10px 0;
        }
        h2 {
          font-size: rem-calc(22);
          color: $color-text;
          text-transform: uppercase;
        }
        h3 {
          font-size: rem-calc(18);
          color: $color-text;
          text-align: inherit;
          text-transform: uppercase;
          margin: 10px 0;
        }
        fieldset {
          border: none;
          margin: 0;
          padding: 0;
          input {
            display: inline-block;
            width: 35%;
            height: 39px;
            padding: 8px;
          }
          button {
            background-color: $color-light-red;
            color: $color-light-red;
            border: none;
            height: 39px;
            width: 65px;
            position: relative;
            &::before {
              position: absolute;
              top: 1px;
              left: 20px;
              font-family: 'fontello';
              content: '\e810';
              display: block;
              color: #000;
              font-size: rem-calc(25);
              color: $color-white;
              transform: rotate(90deg);
            }
          }
        }
      }
      img {
        width: 100%;
      }
      .hoofdpagina-link {
        display: inline-block;
        float: right;
        text-transform: uppercase;
        position: relative;
        a {
          font-size: rem-calc(22);
          color: $color-text;
          &:hover {
            color: $color-light-red;
          }
        }
      }
    }
  }
}
