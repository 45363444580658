.scroll-help {
    z-index: 9999;
    display: none;
    position: fixed;
    right: 35px;
    bottom: 24px;
    transform: translateY(-54px);
    transition: transform 0.2s ease-out;
    display: block;

    &__label {
        position: absolute;
        top: -30px;
        left: 50%;
        transform: translateX(-50%);
        font-size: rem-calc(18);
        line-height: rem-calc(20);
        color: $color-text;
        opacity: 1;
        transition: opacity 0.2s ease-out;
    }

    &__button {
        display: block;
        position: relative;
        width: 36px;
        height: 36px;
        background: #fff;
        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.3);
        border-radius: 50%;
        text-decoration: none;
        cursor: default;

        &:hover {
            text-decoration: none;
        }
    }

    &__icon {
        @include center();
        transition: all 0.2s ease-out;

        path {
            fill: $color-light-red;
        }
    }

    &--scrolled {
        transform: translateY(0);

        .scroll-help__label {
            opacity: 0;
        }

        .scroll-help__icon {
            transform: translate(-50%, -50%) rotate(180deg);
        }

        .scroll-help__button {
            cursor: pointer;

            &:hover {
                background: $color-light-red;

                .scroll-help__path {
                    fill: $color-white;
                }
            }
        }
    }

    &--focus {
        .scroll-help__button {
            animation: scroll-focus ease-out 0.7s infinite;
        }
    }
}

@keyframes scroll-focus {
    0% {
        transform: translateY(-4px);
    }
    50% {
        transform: translateY(4px);
    }
    100% {
        transform: translateY(-4px);
    }
}
