$color-gray-black: #333;
$color-text: $color-gray-black;
$color-almost-black: #262626;
$color-light-grey: #555;
$color-white: #fff;
$color-white-grey: #f4f4f4;
$color-black: #000;
$color-grey: #e8e8e8;
$color-light-red: #ee0000;
$color-dark-red: #b70100;
$color-menu-brown: #b5a47d;
$color-search-red: #e11a31;
$color-mediabank-red: #f20600;
$color-mediabank-grey: #535353;
$color-colofon: #ededed;
$color-colofon-border: #cbcbcb;
$color-copyright-text: #909090;
